import { observable, action, toJS } from 'mobx';
import { GoogleLocationResult, ICalendarEvent, DealType, ItemType, RentType } from 'go2c-shared';
import moment from 'moment';
import { mapEvents, toEventsArray } from '../services/events.service';
import { getEventsForDay } from '../services/items.service';
// import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service';
// import { getEventsForDay } from '../services/items.service';
// import { TimeInDay } from '../components/calendar/Day';
// import { mapEvents, toEventsArray } from '../services/events.service';


export interface INewApartmentsStoreMethods {
    setRawAddress(address: string): void;
    setEmail(address: string): void;
    // setFormByKey(text: string, key: string): void;
    addImage(image: string): void;
    setAddress(address: GoogleLocationResult): void;
    // setFetchDeails(fetchDeails: (place_id: string) => Promise<GoogleLocationDetailResult>): void;
    // fetchDetails(): Promise<GoogleLocationDetailResult>;
    clearForm(): void;
    // setClearAddress(clear: (value: string) => void): void;
    // clearSearch(): void;
    // setClearSearch(clear: () => void): void;
    addEvents(events: ICalendarEvent[]): void;
    removeImage(index: number): void;
    setBedrooms(): void;
    setHasLivingroom(val: boolean): void;
    setRooms(rooms: number): void;
    setSelectedDay(date: Date): void;
    setSelectedDayEvents(): void;
    // setManulalAvailabiliyData(data: TimeInDay): void;
    // setNumricFormByKey(text: number, key: string): void;
    setHasAirConditioning(hasAc: boolean): void;
}

export interface INewApartmentsStore extends INewApartmentsStoreMethods {
    raw_address?: string;
    address_selected: boolean;
    email?: string;
    floor?: number;
    total_floors_in_building?: number;
    price?: number;
    images: string[];
    address?: GoogleLocationResult;
    // detailed_address: GoogleLocationDetailResult;
    type?: string;
    deal_type?: DealType;
    rent_type: RentType;
    events: ICalendarEvent[];
    mapped_events: any;
    day_starts_at: number;
    day_ends_at: number;
    has_livingroom: boolean;
    has_air_conditioning: boolean;
    rooms: number;
    bedrooms: number;
    selected_date: Date;
    selected_date_events: ICalendarEvent[];
    // manual_availability_data: TimeInDay;
    description?: string;
    furniture?: string;
    size?: number;
    // fetch_details?: (place_id: string) => Promise<GoogleLocationDetailResult>;
    // clear_address_function?: (address: string) => void;
    // clear_search_function?: (address: string) => void;
}


export default class NewApartmentStore {
    @observable raw_address?: string;
    @observable address_selected: boolean = false;
    @observable email?: string;
    @observable floor?: number;
    @observable total_floors_in_building?: number;
    @observable price?: number;
    @observable images: string[] = [];
    @observable address?: GoogleLocationResult;
    // @observable detailed_address: GoogleLocationDetailResult;
    @observable type?: ItemType;
    @observable deal_type?: DealType;
    @observable rent_type: RentType = 'monthly';
    @observable events: ICalendarEvent[] = [];
    @observable mapped_events: any;
    @observable day_starts_at: number = 6;
    @observable day_ends_at: number = 6;
    @observable has_livingroom: boolean = true;
    @observable has_air_conditioning: boolean = false;
    @observable rooms: number = 1;
    @observable bedrooms: number = 1;
    @observable selected_date: Date = new Date();
    @observable selected_date_events: ICalendarEvent[] = [];
    // @observable manual_availability_data: TimeInDay;
    @observable description?: string;
    @observable furniture?: string;
    @observable size?: number;

    // @observable fetch_details?: (place_id: string) => Promise<GoogleLocationDetailResult>;
    // @observable clear_address_function?: (address: string) => void;
    // @observable clear_search_function?: (address: string) => void;

    @action
    public setRawAddress(address: string) {
        this.raw_address = address;
    }

    @action
    public setAddress(address: GoogleLocationResult) {
        this.address = address;
        this.address_selected = true;
    }

    @action
    public setEmail(email: string) {
        this.email = email;
    }

    // @action
    // public setFormByKey(text: string, key: string) {
    //     this[key] = text;
    // }

    // @action
    // public setNumricFormByKey(text: number, key: string) {
    //     this[key] = text;
    // }

    @action
    public addImage(image: string) {
        this.images.push(image);
    }

    // @action
    // public setFetchDeails(fetchDeails: (place_id: string) => Promise<GoogleLocationDetailResult>) {
    //     this.fetch_details = fetchDeails;
    // }

    // @action
    // public async fetchDetails(): Promise<GoogleLocationDetailResult> {
    //     return this.fetch_details(this.address.place_id);
    // }

    @action
    public clearForm(): void {
        this.images = [];
        this.email = undefined;
        this.floor = undefined;
        this.price = undefined;
        this.total_floors_in_building = undefined;
        this.description = undefined;
        this.has_livingroom = true;
        this.has_air_conditioning = false;
        this.type = undefined;
        this.deal_type = undefined;
        this.rent_type = 'monthly';
        this.events = [];
        this.mapped_events = undefined;
        this.rooms = 1;
        this.bedrooms = 1;
        this.selected_date = new Date();
        this.selected_date_events = [];
        // this.manual_availability_data = undefined;
        this.furniture = undefined;
        this.size = undefined;
        this.total_floors_in_building = undefined;
        // this.clear_address_function('');
        // this.clear_search_function('');
        // this.raw_address = null;

        // this.clearAddress();
    }

    // @action
    // public setClearAddress(clear: (value: string) => void): void {
    //     this.clear_address_function = clear;
    // }

    // @action
    // public clearAddress() {
    //     this.clear_address_function('');
    //     this.raw_address = null;
    // }

    // @action
    // public setClearSearch(clear: () => void): void {
    //     this.clear_search_function = clear;
    // }

    // @action
    // public clearSearch() {
    //     this.clear_search_function('');
    // }

    @action
    public addEvents(events: ICalendarEvent[]) {
        this.mapped_events = mapEvents(this.events.concat(events));
        this.events = toEventsArray(this.mapped_events);
    }

    @action
    public removeImage(index: number) {
        this.images.splice(index, 1);
    }

    @action
    public setBedrooms() {
        this.bedrooms = this.rooms - (this.has_livingroom ? 1 : 0);
    }
    @action
    public setHasLivingroom(val: boolean) {
        this.has_livingroom = val;
        this.setBedrooms();
    }
    @action
    public setRooms(rooms: number) {
        this.rooms = rooms;
        this.setBedrooms();
    }

    @action
    public setSelectedDay(date: Date) {
        this.selected_date = date;
        this.setSelectedDayEvents();
    }

    @action
    public setSelectedDayEvents() {
        this.selected_date_events = getEventsForDay(this.events, this.selected_date);
    }

    // @action
    // public setManulalAvailabiliyData(data: TimeInDay) {
    //     this.manual_availability_data = data;
    // }

    @action
    public setHasAirConditioning(hasAc: boolean) {
        this.has_air_conditioning = hasAc;
    }

}