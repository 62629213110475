import { observable, action } from 'mobx';
import { ITranslationDictionary, ILanguage, IInitTexts } from 'go2c-shared';

const ActiveLanguages: ILanguage[] = [
    { name: "hebrew", direction: "ltr", country_codes: ["he", "iw_IL"] },
    { name: "english", direction: "rtl", country_codes: ["en", "en_US", "en_UK"] },
    { name: "spanish", direction: "rtl", country_codes: ["es"] },
    { name: "french", direction: "rtl", country_codes: ["fr"] },
    { name: "chinese", direction: "rtl", country_codes: ["cn"] },
    { name: "japanese", direction: "rtl", country_codes: ["jp"] },
    { name: "russian", direction: "rtl", country_codes: ["ru"] },
    { name: "german", direction: "rtl", country_codes: ["de"] },
    { name: "arabic", direction: "ltr", country_codes: ["sa"] },
];


export function getLanguageByCountryCode(country_code: string): ILanguage {
    const allMatches = ActiveLanguages.filter((lang: ILanguage) => {
        return lang.country_codes.indexOf(country_code) > -1;
    });
    return allMatches[0];
}
export const initTexts: any = {
    english: {
        loading: 'Loading',
        fetching_data: 'Fetching data',
        loading_media: 'Loading media',
        finalizing: 'Finalizing',
        slogan: 'Find home... efficently',
    },
    hebrew: {
        loading: 'טוען',
        fetching_data: 'משיג מידע',
        loading_media: 'טוען מדיה',
        finalizing: 'מסיים',
        slogan: 'מצא בית ביעילות',
    },
    spanish: {
        loading: 'cargando',
        fetching_data: 'Recuperacion de datos',
        loading_media: 'Cargando medios',
        finalizing: 'Finalizando',
        slogan: 'Encuentra tu hogar ... eficientemente',
    },
    french: {
        loading: 'chargement',
        fetching_data: 'Récupération des données',
        loading_media: 'Chargement des supports',
        finalizing: 'Finaliser',
        slogan: 'Trouvez la maison ... efficacement',
    },
    chinese: {
        loading: '装货',
        fetching_data: '取得资料',
        loading_media: '载入媒体',
        finalizing: '定稿',
        slogan: '高效地找到家',
    },
    japanese: {
        loading: 'ローディング',
        fetching_data: 'データを取得しています',
        loading_media: 'メディアを読み込んでいます',
        finalizing: 'ファイナライズ',
        slogan: '家を見つける...効率的に',
    },
    russian: {
        loading: 'погрузка',
        fetching_data: 'Получение данных',
        loading_media: 'Загрузка медиа',
        finalizing: 'Доработка',
        slogan: 'Найти дом ... эффективно',
    },
    german: {
        loading: 'Wird geladen',
        fetching_data: 'Daten abrufen',
        loading_media: 'Medien laden',
        finalizing: 'Finalisieren',
        slogan: 'Finden Sie Ihr Zuhause ... effizient',
    },
    arabic: {
        loading: 'جار التحميل',
        fetching_data: 'جلب البيانات',
        loading_media: 'تحميل الوسائط',
        finalizing: 'إنهاء',
        slogan: 'ابحث عن المنزل ... بكفاءة',
    },
}


export interface ITranslationsStoreMethods {
    setTranslations(translations: ITranslationDictionary): void;
    setLanguageCode(code: string): void;
    setUuid(uuid: string): void;
    setCurrency(currency: string): void;
    // setLoadingStatus(status: string): void;
    setCountryCode(code: string): void;
}

export interface ITranslationsStore extends ITranslationsStoreMethods {
    translations?: ITranslationDictionary;
    loading: boolean;
    language_code?: string;
    uuid?: string;
    currency?: string;
    init_texts?: IInitTexts;
    loading_status?: string;
    country_code?: string;
}


export default class TranslationStore {
    @observable translations?: ITranslationDictionary;
    @observable loading: boolean = true;
    @observable language_code?: string;
    @observable country_code?: string;
    @observable uuid?: string;
    @observable currency?: string;
    @observable init_texts?: IInitTexts;
    @observable loading_status?: string;


    @action
    public setTranslations(translations: ITranslationDictionary) {
        this.translations = translations;
        this.loading = false;
    }

    @action
    public setLanguageCode(code: string) {
        this.language_code = code;
        const lang = getLanguageByCountryCode(code);
        console.log('lang.name is: ', lang.name);
        this.init_texts = initTexts[lang.name];
        // this.loading_status = this.init_texts.loading;
    }

    @action
    public setUuid(uuid: string) {
        this.uuid = uuid;
    }

    @action
    public setCurrency(currency: string) {
        this.currency = currency;
    }

    // @action
    // public setLoadingStatus(status: string) {
    //     this.loading_status = this.init_texts[status];
    // }

    @action
    public setCountryCode(code: string) {
        this.country_code = code;
    }

}