import React from 'react';
import { observer } from 'mobx-react';
import { Drawer, makeStyles, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Stores, useStores } from '../../stores';
import { AccountTree, Dns, Home, Print } from '@material-ui/icons';
import views from '../../pages/views';

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    innerList: {
    }
})
);


const Navigation: React.FC = () => {
    const classes = useStyles();
    const stores: Stores = useStores().store;
    const open: boolean = stores.theme.drawer_open;

    const navigate = (view: any, data: any) => {
        stores.router.goTo(view, data);
        // stores.theme.toggleDrawer()
    }

    const sideList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={() => stores.theme.toggleDrawer()}
            onKeyDown={() => stores.theme.toggleDrawer()}
        >
            <List>
                <ListItem onClick={() => navigate(views.main, {})} button>
                    <ListItemIcon><Home /></ListItemIcon>
                    <ListItemText primary={stores.translations.translations?.home} />
                </ListItem>
                <Divider />

                <ListItem onClick={() => navigate(views.about_us, {})} button>
                    <ListItemIcon><Dns /></ListItemIcon>
                    <ListItemText primary={stores.translations.translations?.about_us} />
                </ListItem>


                <ListItem onClick={() => navigate(views.privacy_policy, {})} button>
                    <ListItemIcon><Print /></ListItemIcon>
                    <ListItemText primary={stores.translations.translations?.privacy_policy} />
                </ListItem>


            </List>
            <Divider />
        </div>
    );
    return (
        <Drawer open={open} onClose={() => stores.theme.toggleDrawer()}>
            {sideList()}
        </Drawer>
    );
}

export default observer(Navigation);