import { ICalendarEvent } from "go2c-shared";
import moment, { Moment } from "moment";

export const getEventsForDay = (events: ICalendarEvent[], day: Date): ICalendarEvent[] => {
    let toReturn: ICalendarEvent[] = [];
    const date:Moment = moment(day);
    for (let i = 0; i < events.length; i++) {
        const ev: ICalendarEvent = events[i];
        const evStart:Moment = moment(ev.time_started);
        const isSame:boolean = evStart.isSame(date,'day');
        if (isSame) {
            toReturn.push(ev);
        }
        // const evYear:string = evStart.format('')

    }
    return toReturn;
}