import { observable, action } from 'mobx'

export interface IAuthStore {
    is_authenticated: boolean;
    authenticate(): void;
}

export default class AuthStore {
    @observable is_authenticated: boolean = false;

    @action
    public authenticate() {
        this.is_authenticated = true;
    }
}