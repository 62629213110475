import React from 'react';
import { observer } from 'mobx-react';
import {useStores,Stores} from '../stores';
// import {views} from './views'

const InnerPage:React.FC = ()=>{
    const store:Stores = useStores().store;
    console.log('stores in page is: ',store); 
    return (
        <div>
            Inner page
        </div>
    );
}

export default observer(InnerPage);