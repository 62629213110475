import { observable, action } from 'mobx';
import { IUser, INotification, ICalendarEvent } from 'go2c-shared';
import { mapEvents, toEventsArray } from '../services/events.service';

export interface UserStoreMethods {
    setNotifications(notifications: INotification[]): void;
    setUser(user: IUser): void;
    setEvents(events: ICalendarEvent[]): void;
    setCalendarSelectedDate(date: Date): void;
    setCurrentNeeting(meeting: INotification): void;
    setInit(): void;
}

export interface IUserStore extends UserStoreMethods {
    user?: IUser;
    notifications: INotification[];
    unseen: number;
    mapped_events: any;
    events: ICalendarEvent[];
    calendar_selected_date?: Date;
    meeting_to_approve?: INotification;
    did_init: boolean;
    language_direction: 'rtl' | 'ltr';
}


export default class Userstore {
    @observable user?: IUser;
    @observable notifications: INotification[] = [];
    @observable unseen: number = 0;
    @observable events: ICalendarEvent[] = [];
    @observable mapped_events: any;
    @observable calendar_selected_date?: Date;
    @observable meeting_to_approve?: INotification;
    @observable did_init: boolean = false;
    @observable language_direction: 'rtl' | 'ltr' = 'ltr';

    @action
    public setNotifications(notifications: INotification[]) {
        this.notifications = notifications;
        this.unseen = 0;
        for (let i = 0; i < this.notifications.length; i++) {
            if (!this.notifications[i].seen) {
                this.unseen++;
            }
        }
    }


    @action
    public setUser(user: IUser) {
        this.user = user;
        this.setLanguageDirection();
    }

    @action
    public setEvents(events: ICalendarEvent[]) {
        this.mapped_events = mapEvents(this.events.concat(events));
        this.events = toEventsArray(this.mapped_events);
    }

    @action
    public setCalendarSelectedDate(date: Date) {
        this.calendar_selected_date = date;
    }

    @action
    public setCurrentNeeting(meeting: INotification) {
        this.meeting_to_approve = meeting;
    }

    @action
    public setInit() {
        this.did_init = true;
    }

    @action
    public setLanguageDirection() {
        this.language_direction = this.user && (this.user.language.name === 'hebrew' || this.user.language.name === 'arabic') ? 'rtl' : 'ltr';
    }
}