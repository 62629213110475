import { observable, action } from 'mobx';

export interface IDialogStoreMethods {
    openPopup(title: string, description: string): void;
    closePopup(): void;
}

export interface IDialogStore extends IDialogStoreMethods {
    title: string;
    description: string;
    open: boolean;
}


export default class DialogStore {
    @observable title: string = '';
    @observable description: string = '';
    @observable open: boolean = false;

    @action
    public openPopup(title: string, description: string) {
        this.open = true;
        this.title = title;
        this.description = description;
    }

    @action
    public closePopup() {
        this.open = false;
    }

}