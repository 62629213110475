import { observable, action } from 'mobx';

export interface IThemeStore {
    drawer_open: boolean;
    openDrawer(): void;
    toggleDrawer(): void;
}

export default class ThemeStore {
    @observable drawer_open: boolean = false;

    @action
    public openDrawer() {
        this.drawer_open = true;
    }

    @action
    public toggleDrawer() {
        this.drawer_open = this.drawer_open === false ? true : false;
    }



}