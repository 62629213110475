import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/ar';
import 'moment/locale/he';
import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/ug-cn';
import 'moment/locale/en-gb';
// import 'moment/locale/';

export function setMomentLocale(locale: string) {
    if (locale.indexOf("iw") > -1 && locale.toLocaleLowerCase().indexOf("il") > -1) {
        moment.locale("he");
    } else if (locale.indexOf("en") === -1) {
        moment.locale(locale);
    }
}