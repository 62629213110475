import React, { FunctionComponent } from "react";
import Card from '@material-ui/core/Card';
import { makeStyles, Theme, CardContent, Typography, CardMedia } from "@material-ui/core";




const useStyles = makeStyles((theme: Theme) => ({
    oneGridItem: {
        display: 'flex',
    },
    media: {
        width: "60%",
        height: 400,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: "40%",

    },
    content: {
        flex: '1 0 auto',
    },
    explanation: {
        marginTop: 25,
        fontSize:theme.typography.fontSize
    }
}));






export interface ExplanationCardProps {
    title?: string;
    explanation?: string;
    image?: string;
    cardNumber: number;
}


const ExplanationCard: FunctionComponent<ExplanationCardProps> = (props: ExplanationCardProps) => {
    const classes = useStyles();
    const imageString: string = props.image as string;
    return (
        <Card className={classes.oneGridItem}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {String(props.cardNumber)}. {props.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p" className={classes.explanation}>
                        {props.explanation}
                    </Typography>
                </CardContent>
            </div>
            <CardMedia
                className={classes.media}
                image={require("../../assets/" + imageString)}
                title={props.title}
            />
        </Card>
    )
}
export default ExplanationCard;