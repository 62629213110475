
// import { RouterStore } from 'mobx-router';
import { MobXProviderContext } from 'mobx-react'
import React, { useContext } from 'react';
import { RouterStore } from 'mobx-router';

import ItemsStore, { IItemsStore } from './items.store';
import NewApartmentStore, { INewApartmentsStore } from './newapartment.store';
import TranslationStore, { ITranslationsStore } from './translations.store';
import DialogStore, { IDialogStore } from './dialog.store';
import UserStore, { IUserStore } from './user.store';
import MyItemsStore, { IMyItemsStore } from './my.items.store';
import ThemeStore, { IThemeStore } from './theme.store';
import AuthStore, { IAuthStore } from './auth.store';


export interface Stores {
    items: IItemsStore;
    new_apartment: INewApartmentsStore;
    translations: ITranslationsStore;
    dialog: IDialogStore;
    my_items: IMyItemsStore;
    user: IUserStore;
    theme: IThemeStore;
    router:any;
    auth:IAuthStore;
}

export const stores: Stores = {
    items: new ItemsStore(),
    new_apartment: new NewApartmentStore(),
    translations: new TranslationStore(),
    dialog: new DialogStore(),
    my_items: new MyItemsStore(),
    user: new UserStore(),
    theme: new ThemeStore(),
    router: new RouterStore(),
    auth:new AuthStore(),

}



export function useStores() {
    return React.useContext(MobXProviderContext)
}

export const useStore = () => useContext(MobXProviderContext);