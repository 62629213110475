import React from 'react';
import { observer } from 'mobx-react';
import { useStores, Stores } from '../stores';
// import {views} from './views'
import Featured from '../assets/featured_image.png';

const AboutPage: React.FC = () => {
    const store: Stores = useStores().store;
    return (
        <div>
            <img src={Featured} />
        </div>
    );
}

export default observer(AboutPage);