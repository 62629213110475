import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "mobx-react";
import { startRouter } from 'mobx-router';
import views from './pages/views';
import { stores } from './stores';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const trackingId = "UA-000000-01";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);


startRouter(views, stores);


ReactDOM.render(
    <Provider store={stores}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
